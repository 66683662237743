/* eslint-disable no-console */
import React from 'react';
import Info from '../../components/Info';
import PageDefault from '../../components/PageDefault';

function Informacao() {
  return (
    <PageDefault>
     <Info />
    </PageDefault>
  );
}

export default Informacao;
